
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logo.png';

function MobileMenu() {
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [servicedetailDropdownOpen, setServiceDetailDropdownOpen] = useState(false);
  const [blogDropdownOpen, setBlogDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  
  const location = useLocation(); // Get current pathname

  const toggleServiceDropdown = () => {
    setServiceDropdownOpen(!serviceDropdownOpen);
    setServiceDetailDropdownOpen(!servicedetailDropdownOpen);
  };


  const toggleBlogDropdown = () => {
    setBlogDropdownOpen(!blogDropdownOpen);
  };

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const getLinkStyle = (path) => {
    return location.pathname === path
      ? { color: '#3a9d6a', fontWeight: 'bold' } 
      : { color: '#000', fontWeight: 'bold' }; 
  };

  return (
    <>
   
      <div className="mobile-nav">
        <div onClick={toggleMenu} style={{ cursor: "pointer", justifyContent: 'end', marginTop: '27px', fontSize: '30px', fontWeight: 'bold' }} className="d-block d-lg-none">
          <span className="icon ti-menu" style={{ fontWeight: "bold", color: 'black' }}></span>
        </div>
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            width: "100%",
            height: "100vh",
            backgroundColor: "#fff",
            boxShadow: menuOpen ? "0px 0px 15px rgba(1, 1, 1, 10)" : "none",
            transform: menuOpen ? "translateX(0)" : "translateX(100%)",
            transition: "transform 0.6s ease-in-out",
            zIndex: 1000,
          }}
        >
       
          <div onClick={toggleMenu} style={{ textAlign: "right", fontWeight: "bold" }}>
            <span className="icon lnr lnr-cross" style={{ fontSize: '25px', marginTop: '15px', color: 'black', fontWeight: "bold" }}></span>
          </div>


          <nav className="menu-box">
            <Link to="/"><img src={logo} alt="" title="" width={150} height={100} /></Link>
            <div style={{ padding: '20px', margin: 20, fontFamily: 'Arial' }}>
            
              <Link to="/" style={{ textDecoration: 'none', ...getLinkStyle('/') }}>
                <div style={{ margin: '15px 0', fontSize: '15px', borderBottom: '1px solid #e5e5e5', paddingBottom: '10px' }}>
                  HOME
                </div>
              </Link>

            
              <Link to="/about" style={{ textDecoration: 'none', ...getLinkStyle('/about') }}>
                <div style={{ margin: '15px 0', fontSize: '15px', borderBottom: '1px solid #e5e5e5', paddingBottom: '10px' }}>
                  ABOUT US
                </div>
              </Link>
              <div
                className="dropdown"
                style={{ position: 'relative', margin: '15px 0', fontSize: '14px' }}
                onClick={toggleServiceDropdown}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', paddingBottom: '10px', borderBottom: '1px solid #e5e5e5' }}>
                  <span style={{ fontSize: '15px', fontFamily: 'Arial',fontWeight:'bold',color:'black' }}> SOLUTIONS</span>
                  <div style={{
                    width: '8px',
                    height: '8px',
                    border: 'solid #808080',
                    borderWidth: '0 2px 2px 0',
                    transform: servicedetailDropdownOpen ? 'rotate(-135deg)' : 'rotate(45deg)',
                    transition: 'transform 0.3s'
                  }}></div>
                </div>
                <div style={{ display: servicedetailDropdownOpen ? 'block' : 'none', padding: '10px 0', backgroundColor: '#fff', margin: 0, borderBottom: '1px solid #e5e5e5' }}>
                <Link to="/servicE" style={{ textDecoration: 'none', ...getLinkStyle('/service/wealth') }}>
                    <div style={{ padding: '10px 0' }}>
                    Our Solutions
                    </div>
                  </Link>
                  <Link to="/service/wealth" style={{ textDecoration: 'none', ...getLinkStyle('/service/wealth') }}>
                    <div style={{ padding: '10px 0' }}>
                    Wealth management
                    </div>
                  </Link>
                  <Link to="/service/investment" style={{ textDecoration: 'none', ...getLinkStyle('/service/investment') }}>
                    <div style={{ padding: '10px 0' }}>
                    Investment Advisory
                    </div>
                  </Link>
                  <Link to="/service/financial" style={{ textDecoration: 'none', ...getLinkStyle('/service/financial') }}>
                    <div style={{ padding: '10px 0' }}>
                    Financial Planning
                    </div>
                  </Link>
                  <Link to="/service/corporate" style={{ textDecoration: 'none', ...getLinkStyle('/service/corporate') }}>
                    <div style={{ padding: '10px 0' }}>
                    Corporate Financial Management
                    </div>
                  </Link>
                </div>
              </div>
           
              

            
              <Link to="/faq" style={{ textDecoration: 'none', ...getLinkStyle('/faq') }}>
                <div style={{ margin: '15px 0', fontSize: '15px', borderBottom: '1px solid #e5e5e5', paddingBottom: '10px' }}>
                  FAQ
                </div>
              </Link>
              <div
                className="dropdown"
                style={{ position: 'relative', margin: '15px 0', fontSize: '15px' }}
                onClick={toggleBlogDropdown}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', paddingBottom: '10px', borderBottom: '1px solid #e5e5e5' }}>
                  <span style={{ ...getLinkStyle('/blog'), fontSize: '15px', fontFamily: 'Arial' }}>BLOG</span>
                  <div style={{
                    width: '8px',
                    height: '8px',
                    border: 'solid #808080',
                    borderWidth: '0 2px 2px 0',
                    transform: blogDropdownOpen ? 'rotate(-135deg)' : 'rotate(45deg)',
                    transition: 'transform 0.3s'
                  }}></div>
                </div>
                <div style={{ display: blogDropdownOpen ? 'block' : 'none', padding: '10px 0', backgroundColor: '#fff', margin: 0, borderBottom: '1px solid #e5e5e5' }}>
                  <Link to="/blog" style={{ textDecoration: 'none', ...getLinkStyle('/blog') }}>
                    <div style={{ padding: '10px 0', borderBottom: '1px solid #e5e5e5' }}>
                      Our Blog
                    </div>
                  </Link>
                  <Link to="/blog-detail" style={{ textDecoration: 'none', ...getLinkStyle('/blog-detail') }}>
                    <div style={{ padding: '10px 0' }}>
                      Blog Detail
                    </div>
                  </Link>
                </div>
              </div>
       
              <Link to="/contact" style={{ textDecoration: 'none', ...getLinkStyle('/contact') }}>
                <div style={{ margin: '15px 0', fontSize: '15px', borderBottom: '1px solid #e5e5e5', paddingBottom: '10px' }}>
                  CONTACT US
                </div>
              </Link>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default MobileMenu;
