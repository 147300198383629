import React,{useState,useRef,useEffect} from 'react';
import Footer from '../components/footer'
import Service from '../../src/assets/service2.png';
import Service1 from '../../src/assets/service1.png';
import Service3 from '../../src/assets/service3.png';
import Service4 from '../../src/assets/service4.png';
import S2 from '../assets/investment.png';
import Header from '../components/header'
import { Link } from 'react-router-dom';
import S2Img from '../../src/assets/S2img.png';
import S2Img1 from '../../src/assets/S2img1.png';
import { useLocation } from 'react-router-dom';


function Investment (){
	const [showScroll, setShowScroll] = useState(false);
    const scrollLinkRef = useRef(null);
	const location = useLocation();
	const { name, type } = location.state || { name: '', type: '' }; 
	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

	useEffect(() => {
	  const handleResize = () => {
		setIsLargeScreen(window.innerWidth >= 1024);
	  };
	  window.addEventListener('resize', handleResize);
	  return () => {
		window.removeEventListener('resize', handleResize);
	  };
	}, []);

  return (
    <div className="page-wrapper">
     <Header/>

	
    <div className="sidebar-page-container">
    	<div className="auto-container">
			<br/>
			{name && type ? (
      <>
        <h1 style={{ fontWeight: 'normal', color: 'black' }}>Welcome, {name}!</h1>
        <p style={{ fontSize: '30px', color: 'black' }}>As a {type} person, here are the services we recommend for you:</p>
      </>
    ) :  <h1 style={{ color: 'black',fontWeight:"inherit" }}></h1>}
			<div className="service-title-box">
			<br/>
				<h2 style={{ color: 'black',fontWeight:"normal" ,fontWeight:"bold"}}>Investment Advisory</h2>
			</div>
        	<div className="row clearfix">
            	
           
                <div className="content-side col-lg-9 col-md-12 col-sm-12 animate__animated animate__fadeInUp">
					<div className="service-detail">
						<div className="inner-box">
							<div className="image">
								<img src={Service} alt="" />
							</div>
							<div className="lower-content">
								<h4 className='text-black'>Expert Guidance to Navigate Financial Markets with Confidence
                                </h4>
								<p style={{fontSize:"18px"}}>With markets constantly evolving, making the right investment decisions can be challenging. Our Investment Consultancy service leverages our deep market expertise to guide you in developing and executing effective investment strategies. We bring a combination of economic insights, market analysis, and experience to help you make well-informed choices.
                s</p>
							
								
								
						
								
							
								
								
								
							</div>
						</div>
					</div>
					<div style={{ maxWidth: "1200px", marginTop: "40px", marginBottom: "30px" }} className="container">
      <div className="row">
      <div className="col-sm-6 animate__animated animate__fadeInLeft animate__delay-1s" style={{ height: "100%" }}>
          <h4
            style={{
              color: "#3a9d6a",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Our Investment Consultancy
          </h4>
          <div className="row" style={{ height: "100%" }}>
            <div className="col-xs-6 mt-2" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <h5 className='text-black'>
                <span
                  style={{
                    color: "red",
                    marginRight: "5px",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-chart-line text-success"></i>
                </span>
                Customized Investment Advice
              </h5>
              <p style={{ fontSize: "15px" }}>
              Whether you are a seasoned investor or new to investing, we offer advice tailored to your specific goals and risk profile.
              </p>
            </div>
            <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <h5 className='text-black'>
                <span
                  style={{
                    color: "red",
                    marginRight: "5px",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-chart-bar text-success"></i>
                </span>
                Market and Sector Analysis 
              </h5>
              <p style={{ fontSize: "15px" }}>
              We provide in-depth analysis of various sectors and asset classes, identifying opportunities and risks.
              </p>
            </div>
            <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <h5 className='text-black'>
                <span
                  style={{
                    color: "red",
                    marginRight: "5px",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-shield-alt text-success"></i>
                </span>
                Risk Assessment and Management 
              </h5>
              <p style={{ fontSize: "15px" }}>
              Our consultants guide you in evaluating potential risks and setting strategies for risk mitigation, ensuring your portfolio aligns with your tolerance levels.
              </p>
            </div>
            <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <h5 className='text-black'>
                <span
                  style={{
                    color: "red",
                    marginRight: "5px",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-headset text-success"></i>
                </span>
                Ongoing Support and Monitoring 
              </h5>
              <p style={{ fontSize: "15px" }}>
              From initial consultations to continuous monitoring, we support your financial journey every step of the way.
              </p>
            </div>
          </div>
        </div>
        {/* Left Image Section */}
        <div className="col-sm-6 animate__animated animate__fadeInUp animate__delay-1s" style={{ position: "relative", height: "100%" }}>
          <img
            src={S2Img1}
            alt="Team"
            style={{
              width: "100%",
              height: "50vh", // Ensure the image takes the full height of the container
              borderRadius: "5px",
              objectFit: "cover", // Ensures the image covers the area without distorting
            }}
          />
          {/* Overlay Testimonial (if needed) */}
        </div>

    
     				</div>
				</div>
        <div
        className="image-container animate__animated animate__fadeInUp animate__delay-2s"
        style={{
          position: 'relative',
          width: '100%',
          maxHeight: '400px'
        }}
      >
        <img
          src={S2}
          alt="Background"
          style={{
            width: '100%',
            maxHeight: '400px',
            height: 'auto',
            objectFit: 'cover',
          }}
        />
        <div
          className="overlay"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            zIndex: 1,
          }}
        />
        <div
          className="text animate__animated animate__fadeInRight animate__delay-3s"
          style={{
            position: 'absolute',
			      top: isLargeScreen?'40%':'10%',
            left: isLargeScreen?'40%':'5%',
            transform: isLargeScreen?'translate(-10%, -380%)':'translate(-50%, -200%)',
            color: 'white',
            fontSize: '30px',
            fontWeight: 'bold',
            zIndex: 2,
          }}
        >
         Investment Consultancy
        </div>
		<div
          className="text animate__animated animate__fadeInRight animate__delay-3s"
          style={{
            position: 'absolute',
            top: isLargeScreen?'50%':'25%',
            left: isLargeScreen?'40%':'5%',
            transform: isLargeScreen?'translate(-5%, -30%)':'translate(-50%, -15%)',
            color: 'white',
            fontSize: '14px',
            fontWeight: 'bold',
            zIndex: 2,
          }}
        >
         Our consultancy team empowers clients by providing the insights and confidence needed to capitalize on market opportunities, ensuring that your investment strategies align with both market trends and your personal objectives.
        
		</div>
		<div className="col-md-5 col-sm-12 text-center "  style={{
            position: 'relative',
            zIndex: 10,
            transform:isLargeScreen?'translateY(-300px)':'translateY(20px)', 
          }}>
      <img 
        src={S2Img} 
        alt="Contact Form Illustration" 
        style={{ maxWidth: isLargeScreen?'100%':'80%', height: 'auto' }} 
      />
    </div>
      </div>
	
        

				</div>
             
					
				
				
                <div className="sidebar-side col-lg-3 animate__animated animate__fadeInUp animate__delay-1s" style={{marginTop:isLargeScreen?"":"150px"}}>
                	<aside className="sidebar sticky-top">
						
                    
                        <div className="sidebar-widget categories-widget">
                        <h4 className='text-black'> View Other Services</h4>
						<div className="category-boxed mb-4">
                            <Link to="/service/wealth" className="relative block rounded-lg overflow-hidden">
                                <div className="boxed-inner flex flex-col justify-center items-center p-1 bg-gray-100 rounded-lg transition-shadow duration-300 hover:shadow-lg">
                                <img src={Service1} alt="Portfolio management" className="w-24 h-24 object-cover rounded-lg mb-4" />
                                <h6 className="text-lg font-semibold text-gray-800 text-center">Wealth management </h6>
                                </div>
                            </Link>
                            </div>
                    


                            <div className="category-boxed mb-4">
                            <Link to="/service/financial" className="relative block rounded-lg overflow-hidden">
                                <div className="boxed-inner flex flex-col justify-center items-center p-1 bg-gray-100 rounded-lg transition-shadow duration-300 hover:shadow-lg">
                                <img src={Service3} alt="Wealth Planning" className="w-24 h-24 object-cover rounded-lg mb-4" />
                                <h6 className="text-lg font-semibold text-gray-800 text-center">Financial Planning</h6>
                                </div>
                            </Link>
                            </div>

                            <div className="category-boxed mb-4">
                            <Link to="/service/corporate" className="relative block rounded-lg overflow-hidden">
                                <div className="boxed-inner flex flex-col justify-center items-center p-1 bg-gray-100 rounded-lg transition-shadow duration-300 hover:shadow-lg">
                                <img src={Service4} alt="Corporate Financial Management" className="w-24 h-24 object-cover rounded-lg mb-4" />
                                <h6 className="text-lg font-semibold text-gray-800 text-center">Corporate Financial Management</h6>
                                </div>
                            </Link>
                            </div>

							
	
							
							
						</div>
						
						
					</aside>
				</div>
				
			</div>
		</div>
	</div>
      <Footer />
	  <div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>
    </div>
  );
};

export default Investment;
 