
import Header from '../../src/components/header'
import Footer from '../../src/components/footer'
import React, { useEffect ,useState,useRef} from 'react';
import Index from '../components/index';
import CFI from '../../src/assets/cfi.png';
import Royal from '../../src/assets/royal.png';
import Tickmill from '../../src/assets/tickmill.png';
import MultiBank from '../../src/assets/multibank.png';
import Interactive from '../../src/assets/interactive.png';
import T4 from '../../src/assets/4t.png';
import InvestmentProcess from '../components/InvestementProcess';
import HomeAbout from '../../src/assets/about.jpeg';
import CEO from '../../src/assets/ceo.webp';
import carousel from '../../src/assets/carousel.png';
import TestimonialSection from '../components/Testimonial';
import ContactImage from '../assets/contact.png';
import Port from '../../src/assets/port.jpg';
import Phone from '../../src/assets/phone.jpg';
import '../css/bootstrap.min.css';
import Money from '../../src/assets/money.jpg';
import { Link } from 'react-router-dom';
import WhyChooseUs from '../components/why_choose_us';
import OurServices from '../components/Our_Services';
import { useNavigate } from 'react-router-dom';
import WorldMap from '../components/world_map';

function Home() {
  const navigate = useNavigate();
    const [isFixed, setIsFixed] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const scrollLinkRef = useRef(null);


  const handleScroll = () => {
    const windowPos = window.scrollY;

    if (windowPos > 100) {
      setIsFixed(true);
      setShowScroll(true);
    } else {
      setIsFixed(false);
      setShowScroll(false);
    }
  };

  useEffect(() => {
  
    window.addEventListener('scroll', handleScroll);

    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const hasClicked = useRef(false);

  useEffect(() => {
    if (!hasClicked.current) {
      const button = document.querySelector('.carousel-control-next');
      if (button) {
        const timer = setTimeout(() => {
          button.click();
          hasClicked.current = true; 
        }, 2000); 

        return () => clearTimeout(timer);
      }
    }
  }, []);

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


const isBigScreen = window.innerWidth > 1500; 

useEffect(() => {
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 768);
  };
  handleResize(); 
  window.addEventListener("resize", handleResize); 
  return () => window.removeEventListener("resize", handleResize); 
}, []);


  

  
 
  


    return (
        <>
           
            <div className="page-wrapper">

                <Header/>
                <div
  className="container-fluid py-5"
  style={{
    backgroundColor: '#f4f4df',
    backgroundImage: `url(${ContactImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',  // Adjust as needed
  }}
>
      <div className="container " style={{marginTop:'40px'}}>
        <div className="row text-center align-items-center">
          
         
          <div className="col-md-4" style={{
            position: 'relative',
            zIndex: 10,
            transform: isLargeScreen?'translateX(100px)':'',
            marginBottom:isLargeScreen?"5px":""
          }}>
         <h1 className="display-4 fw-bold mb-4 animate__animated animate__fadeInLeft" style={{ lineHeight: '1.6', color: '#333', fontSize: '45px' }}>
        Engineering Your <br />
  With Precision
</h1>

            <h1 className="display-4 fw-bold mb-4 animate__animated animate__fadeInLeft" style={{ lineHeight: '1', color: '#333' ,fontSize:'50px'}}>
            Your Capital'<span style={{color:isLargeScreen?"white":"black"}}>s</span> 
            </h1>
            <h1 className="display-4 fw-bold mb-4 animate__animated animate__fadeInLeft" style={{ lineHeight: '1', color: '#333' ,fontSize:'50px'}}>
           <span style={{color:'#3a9d6a'}}>Safe Haven</span> 
            </h1>

            <button className="btn btn-success mt-3 animate__animated animate__fadeInUp animate__delay-1s"
        style={{
          background: 'linear-gradient(135deg, #28a745, #218838)',
          color: '#fff',
          padding: '12px 24px',
          fontSize: '1.1rem',
          fontWeight: 'bold',
          borderRadius: '30px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.transform = 'scale(1.05)';
          e.currentTarget.style.boxShadow = '0px 6px 12px rgba(0, 0, 0, 0.3)';
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.transform = 'scale(1)';
          e.currentTarget.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)';
        }}
        onClick={() => {
          const formElement = document.getElementById("form");
          if (formElement) {
            formElement.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }}
         >
  Start Investment
</button>
          </div>

        
          <div className="col-md-4 col-12 d-flex justify-content-center align-items-center">
            <div style={{ position: 'relative', width: '99%', maxWidth: '450px' }}>
              <img src={carousel} alt="Center Image" className="img-fluid animate__animated animate__zoomIn" />
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              </div>
            </div>
          </div>

          
          <div className="col-md-4 col-12" style={{
            position: 'relative',
            zIndex: 10,
            transform: isLargeScreen?'translateX(-95px)':'', 
               marginTop:isLargeScreen?"60px":""
          }}>
          <h1 className="display-4 fw-bold mb-4 animate__animated animate__fadeInLeft" style={{ lineHeight: '1.6', color: '#333' ,fontSize:'50px'}}>
          <span style={{color:isLargeScreen?"white":"black"}}>F</span>inancial  Future   <br/>
          <span style={{color:isLargeScreen?"white":"black"}}>A</span>nd Innovation.
            </h1>
           <br/>
           <br/>
           <br/>
           <br/>
            <h1 className="display-4 fw-bold mb-4 animate__animated animate__fadeInLeft" style={{ lineHeight: '1.2', color: '#333' ,fontSize:'30px'}}>
            Our Finest Solutions:
            </h1>
          
           
            <div>
  <button style={{borderRadius:"25px",color:'black',  backgroundColor: 'rgba(255, 255, 255, 0.6)',   fontSize: '0.9 rem', padding: isLargeScreen?"10px 10px":"5px 5px"}} className=' animate__animated animate__fadeInUp animate__delay-1s'  onClick={() => navigate("/service/wealth")}>
    <Link to="/service/wealth" style={{color:'black'}}>
      Wealth Management
    </Link>
  </button>
  <button  style={{borderRadius:"25px",color:'black',marginLeft:"8px",  backgroundColor: 'rgba(255, 255, 255, 0.6)',fontSize: '0.9 rem', padding: isLargeScreen?"10px 10px":"5px 5px"}} className='animate__animated animate__fadeInUp animate__delay-2s'  onClick={() => navigate("/service/investment")}>
    <Link to="/service/investment" style={{color:'black'}}>
      Investment Advisory
    </Link>
  </button>
</div> 
<br/>
<div>
  <button style={{borderRadius:"25px",color:'black', backgroundColor: 'rgba(255, 255, 255, 0.6)',marginRight:"8px" ,fontSize: '0.9 rem', padding: isLargeScreen?"10px 10px":"5px 5px" ,marginBottom:isLargeScreen?"":"18px"}} className='animate__animated animate__fadeInUp animate__delay-3s'  onClick={() => navigate("/service/financial")}>
    <Link to="/service/financial" style={{color:'black'}}>
    Financial Planning
    </Link>
  </button>
  <button  style={{borderRadius:"25px",color:'black', backgroundColor: 'rgba(255, 255, 255, 0.6)',fontSize: '0.9 rem', padding: isLargeScreen?"10px 10px":"5px 5px"}} className='animate__animated animate__fadeInUp animate__delay-4s'  onClick={() => navigate("/service/corporate")}>
    <Link to="/service/corporate" style={{color:'black'}}>
      Corporate Financial  Management
    </Link>
  </button>
</div>






          </div>

        </div>
      </div>
    </div>


    <div className="about-section animate__animated animate__fadeInUp animate__delay-1s" style={{ padding: '30px 0' }}>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-10">
        <div className="about-column">
          <div  style={{ marginBottom: '10px' }}>
            <h3 className="text-uppercase" style={{ color: '#3a9d6a', fontSize: '20px', fontWeight: 'bold' }}>
              About Beta
            </h3>
            <h2 style={{ fontWeight: 'bold', fontSize: '30px', color: '#333', lineHeight: '1.3' }}>
              Your
              <span style={{ color: '#3a9d6a' }}> Trusted Partner </span>
              in Strategic Wealth Growth
            </h2>
          </div>

          <div
            className="about-image"
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              position: 'relative',
            }}
          >
            <img
              src={HomeAbout}
              alt="about"
              className="img-responsive mt-4"
              style={{
                borderRadius: '10px',
                maxHeight: '100%',
                width: isBigScreen?"92%":"100%",
                marginLeft:isBigScreen?"90px":""
              }}
            />
            <Link
              to="/about"
              className="btn btn-success btn-lg"
              style={{
                position: 'absolute',
                top: '-5px',
                right: '30px',
                borderRadius: '50%',
                width: '100px',
                height: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
                backgroundColor: 'white',
                border: '1px solid transparent',
              }}
            >
             <Link
      to="/about"
      className="btn btn-success btn-lg"
      style={{
        position: 'absolute', 
        bottom:'8px', 
        right: '9px', 
        borderRadius: '50%', 
        width: '80px',
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '18px', 
        fontWeight: 'bold',
        textAlign: 'center', 
      }}
    >
   
      Join Us
</Link>
            </Link>
          </div>

          {/* Two Columns Section */}
          <div className="row mt-4">
            {/* Our Goal */}
            <div className="col-sm-12 col-md-6" style={{marginBottom:isLargeScreen?"":"20px"}}>
              <div
                className="well"
                style={{
                  backgroundColor: '#fefefe',
                  padding: '20px 10px',
                  borderRadius: '10px',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  height: '100%',
                  width: '100%'
                }}
              >
                <h4
                  className="text-uppercase text-start"
                  style={{
                    color: '#000080',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                  }}
                >
                  Our Goal
                </h4>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '25px',
                    color: '#333',
                    marginBottom: '5px',
                  }}
                  className="text-start"
                >
                  Making Investments Simple, Secure, And Accessible
                </h5>
                <p style={{ fontSize: '14px', color: '#777' ,}} className="text-start">
                  At Beta Investment Group, we believe that everyone deserves the opportunity to grow their wealth, no matter their background or experience. That’s why we’re committed to making investment simple, secure, and accessible for everyone.
                </p>
              </div>
            </div>

            {/* Our Mission */}
            <div className="col-sm-12 col-md-6">
              <div
                className="well"
                style={{
                  backgroundColor: '#eaf8ec',
                  padding: '20px 10px',
                  borderRadius: '10px',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  height: '100%',
                  width: '100%', // Ensure this is 100% of its container
                }}
              >
                <h4
                  className="text-uppercase text-start"
                  style={{
                    color: '#3a9d6a',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                  }}
                >
                  Our Mission
                </h4>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '25px',
                    color: '#333',
                    marginBottom: '5px',
                  }}
                  className="text-start"
                >
                  Ensuring tailored financial <br/>growth
                </h5>
                <p style={{ fontSize: '14px', color: '#777' }} className="text-start">
                  At Beta, we provide expert-driven investments and personalized strategies that combine innovation, transparency, and a focus on growing your wealth. We’re dedicated to offering you the tools and insights you need to make informed decisions for long-term success.
                </p>
              </div>
            </div>
          </div> {/* End of Two Columns Section */}
        </div>
      </div>
    </div>
  </div>
</div>





<OurServices/>
   
  
<section
      style={{
        backgroundColor: "#fff",
        padding: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        textAlign: "left",
        flexDirection: isLargeScreen ? "row" : "column", // Change to column on smaller screens
        overflow:"hidden"
      }}
    >
      <div>
        <h4
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
            position: "relative",
            transform: isLargeScreen ? "translateX(45px)" : "", // Adjust position for large screens
          }}
          className="text-success"
        >
          Partners
        </h4>
        <h3
          style={{
            color: "#222",
            fontWeight: "bold",
            marginBottom: "30px",
            fontSize:"32px",
            position: "relative",
            transform: isLargeScreen ? "translateX(45px)" : "", // Adjust position for large screens
          }}
        >
          Seamless Partners, Secure Investments
        </h3>
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center", // Center logos
          transform: isLargeScreen?'translateX(150px)':'',
          gap: "5px", // Add spacing between logos
        }}
      >
        {[
          { src: CFI, alt: "Ozigan" },
          { src: Interactive, alt: "Capture" },
          { src: Royal, alt: "Glamour" },
          { src: MultiBank, alt: "Gagod" },
          { src: Tickmill, alt: "Grissee" },
          { src: T4, alt: "Debora" },
        ].map((partner, index) => (
          <div
            key={index}
            style={{
              flexBasis: isLargeScreen ? "30%" : "33.33%", // Three logos per row on small screens
              textAlign: "center",
            }}
          >
            <img
              src={partner.src}
              alt={partner.alt}
              style={{
                width: "80%",
                height: "80px",
                objectFit: "contain",
                display: "block",
                margin: "auto",
              }}
            />
          </div>
        ))}
      </div>
    </section>


   <InvestmentProcess/>
    <br/>
    <WorldMap/>

<div className="experts-section" style={{ padding: '20px' }}>
  <div className="auto-container" style={{ maxWidth: '1200px', margin: '0 auto' }}>
    <div className="sec-title">
      <h2 style={{ marginBottom: '20px' ,fontWeight:'bold'}} className="animate__animated animate__fadeInUp animate__delay-3s text-success text-uppercase">Letter from the CEO</h2>
    </div>

    <div className="row clearfix" style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center' }}>
      <div className="team-block" style={{ flex: '1', display: 'flex', alignItems: 'center', marginBottom: isSmallScreen ? '20px' : '0' }}>
        <div className="inner-box" style={{ flex: '1', display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: isSmallScreen ? 'center' : 'flex-start' }}>
          <div className="image animate__animated animate__fadeInUp animate__delay-3s" style={{ marginRight: isSmallScreen ? '0' : '20px', marginBottom: isSmallScreen ? '10px' : '0' }}>
          <div
  style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '20px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px', 
    margin: 'auto',
  }}
>
  <img
    src={CEO}
    alt="CEO"
    style={{
      width: '120%', // Make the image larger
      height: 'auto',
      objectFit: 'cover',
      // Optional: Rounded corners for a polished look
    }}
  />
  <span
    style={{
      color: '#000',
      fontSize: '18px',
      fontWeight: 'bold',
      textAlign: 'center',
    }}
  >
    Raoul Aoun – CEO, Beta Investment Group
  </span>
</div>

            <ul className="social-box" style={{ display: 'flex', gap: '6px', padding: '4px', listStyle: 'none', width: '200px' }}>
              {['facebook', 'twitter', 'instagram', 'whatsapp'].map((platform, index) => (
                <li key={index}>
                  <Link
                    to={`https://${platform}.com/`}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '15px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #ccc',
                      backgroundColor: '#f9f9f9',
                      transition: 'background-color 0.3s ease, border-color 0.3s ease',
                      textDecoration: 'none',
                      color: 'inherit',
                      position: 'relative',
                    }}
                    aria-label={platform}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = platform === 'facebook' ? '#1877f2' : platform === 'twitter' ? '#1da1f2' : platform === 'instagram' ? '#dd2a7b' : '#25d366';
                      e.currentTarget.style.color = 'white';
                      e.currentTarget.style.borderColor = e.currentTarget.style.backgroundColor;
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = '#f9f9f9';
                      e.currentTarget.style.color = 'inherit';
                      e.currentTarget.style.borderColor = '#ccc';
                    }}
                  >
                    <i className={`icofont-${platform}`} style={{ fontSize: '16px' }}></i>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div style={{ flex: '1', marginLeft: isSmallScreen ? '0' : '20px', color: 'black', textAlign: isSmallScreen ? 'center' : 'left' }} className="animate__animated animate__fadeInRight animate__delay-4s mt-5">
            <p style={{ fontSize: '17px', lineHeight: '1.6' }}>
              Dear Valued Stakeholders, <br /><br />
              As we reflect on our journey at Beta Investment Group, I am filled with a profound sense of gratitude and optimism. 
              Since our establishment in 2024, our commitment has been to navigate the complex financial landscape and provide tailored 
              solutions that empower the Lebanese people. <br /><br />
              In a time marked by economic challenges, we have remained steadfast in our mission. Our team has worked diligently to 
              adapt our strategies, ensuring that we not only meet the needs of our clients but also anticipate future trends in the market. 
              We believe in the power of innovation and collaboration... <br /><br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<br/>    
<TestimonialSection/>





<div id="form">

<div className="text-white">OUR BLOG</div>
          <h2 className="text-white">From Our Press</h2>

</div>
                <Index/>


<br/>
               
      
                <div>
                    <div className="auto-container">

                        <div>
                            <div className="title text-success text-uppercase" style={{fontSize:"30px",fontWeight:"bold"}}>our blog</div>
                            <h2 style={{color:'black',fontSize:isLargeScreen?"40px":'30px'}}><span>Latest </span>From Our Press</h2>
                        </div>
                        <div className="inner-container">
                            <div className="clearfix row g-0">

                                <div className="column col-lg-8 col-md-12 col-sm-12">


                                    <div className="news-block">
                                        <div className="inner-box">
                                            <div className="clearfix">

                                                <div className="image-column col-lg-6 col-md-6 col-sm-12">
                                                    <div className="inner-column">
                                                        <div className="image">
                                                            <Link to="blog-detail"><img src={Port} alt="" /></Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="content-column col-lg-6 col-md-6 col-sm-12">
                                                    <div className="inner-column">
                                                        <div className="arrow-one"></div>
                                                        <div className="title">business</div>
                                                        <h4><Link to="blog-detail">Problems About <br></br> Social Insurance For <br></br> Truck Drivers</Link></h4>
                                                        <div className="post-date">Decmber 14th, 2024</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="news-block">
                                        <div className="inner-box">
                                            <div className="clearfix row g-0">

                                                <div className="content-column col-lg-6 col-md-6 col-sm-12 order-lg-1 order-2">
                                                    <div className="inner-column">
                                                        <div className="arrow-two"></div>
                                                        <div className="title">bank & finance</div>
                                                        <h4><Link to="blog-detail2">Payment Online - <br></br> Things That You Know To <br></br> Project Your Money</Link></h4>
                                                        <div className="post-date">Decmber 14th, 2024</div>
                                                    </div>
                                                </div>

                                                <div className="image-column col-lg-6 col-md-6 col-sm-12 order-lg-2 order-1">
                                                    <div className="inner-column">
                                                        <div className="image">
                                                            <Link to="blog-detail2"><img src={Money} alt="" /></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="column col-lg-4 col-md-12 col-sm-12">

                                    <div className="news-block-two">
                                        <div className="inner-box">
                                            <div className="image">
                                                <Link to="blog-detail1"><img src={Phone} alt="" /></Link>
                                                <div className="arrow"></div>
                                            </div>
                                            <div className="lower-content">
                                                <div className="title">tips & tricks</div>
                                                <h4><Link to="/blog-detail1">5 Secrets To <br></br> Coaching Your Employees <br></br> To Greatness</Link></h4>
                                                <div className="post-date">Decmber 14th, 2024</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
               

                <Footer />

            </div>

            <div className="search-popup">
                <button className="close-search style-two"><span className="icofont-brand-nexus"></span></button>
                <button className="close-search"><span className="icofont-arrow-up"></span></button>
                <form method="post" action="blog">
                    <div className="form-group">
                        <input type="search" name="search-field" placeholder="Search Here" required="" />
                        <button type="submit"><i className="fa fa-search"></i></button>
                    </div>
                </form>
            </div>

            <div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>
      
        </>
    );
}

export default Home;
