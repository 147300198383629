import React, { useState ,useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import MiddleEast from '../assets/middleeast.png';
import Asia from '../assets/asia.png'; 
import Africa from '../assets/africa.png'; 
import Europe from '../assets/europe.png';
import NorthAmerica from '../assets/northamerica.png'; 
import SouthAmerica from '../assets/southamerica.png';
import Oceania from '../assets/oceania.png';
import Unlock1 from '../../src/assets/unlock1.png';
import Unlock2 from '../../src/assets/unlock2.png';

function WorldMap() {
  const isLargeScreen = window.innerWidth >= 1024;
  const location = useLocation();
  const [selectedRegion, setSelectedRegion] = useState('Middle East');
  const [hoveredRegion, setHoveredRegion] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 768);
      };
      
      window.addEventListener('resize', handleResize);
      
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);




      const [isUnlock1, setIsUnlock1] = useState(true); 
useEffect(() => {
  const interval = setInterval(() => {
    setIsUnlock1((prev) => !prev); 
  }, 3000); 

  return () => clearInterval(interval); 
}, []);

useEffect(() => {
  // Update the screen size on mount and window resize
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 768); // Adjust for small screens
  };
  handleResize(); // Check screen size on component mount
  window.addEventListener("resize", handleResize); // Listen for screen resize
  return () => window.removeEventListener("resize", handleResize); // Cleanup
}, []);
  const navigate = useNavigate();

  const regionImages = {
    Asia: Asia,
    'Middle East': MiddleEast,
    Europe: Europe,
    Africa: Africa,
    'North America': NorthAmerica,
    'South America': SouthAmerica,
    'Oceania': Oceania,
  };
  
  return (
    <>
    <div
      className="container-fluid"
      style={{
        backgroundColor: '#F4F2E3',
        padding: '40px 20px',
      }}
    >
      <div className="row">
        <div className="col-xs-12 text-left" >
          <p
            style={{
              color: '#3a9d6a',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            marginLeft:"30px",
              marginBottom: '5px',
              fontSize: isLargeScreen ? '25px' : '15px',
            }}
          >
            Your Financial Partner , Across Borders
          </p>
          <h2
            style={{
              fontWeight: 'bold',
              fontSize: isLargeScreen ? '40px' : '25px',
              marginBottom: '30px',
              marginLeft:"30px",
              color: "#13264C",
            }}
          >
            World Wide Locations
          </h2>
        </div>
      </div>

      <div className="row">
  {/* Image Section */}
  <div
    className="col-xs-12 col-md-8 text-center"
    style={{
      position: 'relative',
      margin: '0 auto',
      textAlign: 'center',
      marginBottom: '10px', // Reduced margin to make the space smaller
      display: 'flex',
      justifyContent: 'center', // Centers the image horizontally
      alignItems: 'center',    // Centers the image vertically if needed
    }}
  >
    {selectedRegion && (
      <div
        style={{
          position: 'relative', // Keeps it centered
        }}
        className="animate__animated animate__fadeInUp"
      >
        <img
          src={regionImages[selectedRegion]} // Dynamically updates the image
          alt={selectedRegion}
          style={{
            width: '1000px',
            borderRadius: '4px',
            transition: 'filter 0.3s ease',
          }}
        />
      </div>
    )}
  </div>

  {/* Region List Section */}
  <div
   className={`col-xs-12 col-md-4 ${isLargeScreen ? 'text-start' : 'text-center'}`}   // Centers text alignment
    style={{
      marginTop: '20px', // Reduced margin for closer placement to the image
    }}
  >
    <ul
      className="list-unstyled"
      style={{
        fontSize: '20px',
        lineHeight: '2',
        display: 'inline-block',
        textAlign: 'left', // Aligns text if needed
      }}
    >
      {Object.keys(regionImages).map((region) => (
        <li key={region} style={{ marginBottom: '10px' }}>
          <span
            onClick={() => setSelectedRegion(region)}
            onMouseEnter={() => {
              setHoveredRegion(region);
              setSelectedRegion(region);
            }}
            
            onMouseLeave={() => setHoveredRegion(null)}
            style={{
              cursor: 'pointer',
              color:
                selectedRegion === region || hoveredRegion === region
                  ? '#3a9d6a'
                  : '#000',
              fontWeight:
                selectedRegion === region || hoveredRegion === region
                  ? 'bold'
                  : 'normal',
              fontSize:
                selectedRegion === region || hoveredRegion === region
                  ? isLargeScreen
                    ? '30px'
                    : '22px'
                  : isLargeScreen
                  ? '20px'
                  : '16px',
              transition: 'all 0.3s ease',
            }}
            className={`animate__animated ${
              selectedRegion === region || hoveredRegion === region
                ? 'animate__fadeInUp'
                : ''
            }`}
          >
            {region}
          </span>
        </li>
      ))}
    </ul>
  </div>
</div>

    </div>
  <div
    className="container-fluid"
    style={{
      position: "relative",
      width: "100vw",
      margin: "0",
      padding: "0",
    }}
  >
    <div
      style={{
        backgroundImage: `url(${isUnlock1 ? Unlock1 : Unlock2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "70vh",
        position: "relative",
      }}
    >
      {/* Overlay */}
      <div
        className="overlay"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 1,
        }}
      />

      <div
        className="container text-white d-flex flex-column justify-content-center align-items-center"
        style={{
          position: "absolute",
          top: isSmallScreen ? "3%" : "25%",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 2,
          padding: "0 15px",
        }}
      >
       <div style={{ textAlign: "center", margin: "0 auto" }}>
  <h1 
    className="fw-bold" 
    style={{ 
      fontSize: isLargeScreen ? "2.5rem" : "1.5rem", 
      display: "inline-block", 
      margin: "0" 
    }}>
    Our Commitment to Your Financial Success
  </h1>
  <p 
    style={{ 
      fontSize: isLargeScreen ? "1rem" : "0.8rem", 
      margin: "20px 0", 
      display: "inline-block", 
      width: "60%", 
      textAlign: "center"
    }} 
    className="text-center">
    At Beta, we combine innovative strategies with a commitment to transparency and client success. We offer personalized investment solutions, guided by a deep understanding of global markets, to help you grow and protect your wealth. Whether you're an experienced investor or just starting, we're here to support you every step of the way.
  </p>
</div>



        <button
          className="btn btn-outline-light"
          style={{
            borderRadius: "25px",
            fontSize: "1rem",
            backgroundColor: isHovered ? "#3a9d6a" : "transparent",
            borderColor: isHovered ? "#3a9d6a" : "#fff",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Link
            to="/contact"
            style={{
              color: isHovered ? "#fff" : "#fff",
              textDecoration: "none",
            }}
          >
            Let’s Connect
          </Link>
        </button>
      </div>

      {/* Stats Section */}
      <div
      className="container-fluid d-flex justify-content-between align-items-center text-white flex-wrap"
      style={{
        position: "absolute",
        bottom: "10px", // Position at the bottom
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        zIndex: 3,
        width: "calc(100% - 20px)", // Prevent overflow
        boxSizing: "border-box",
      }}
    >
      {[
        { count: "5+", label: "Years Experience" },
        { count: "4", label: " Investment Solutions" },
        {
          count: (
            <>
              <i className="fa fa-star text-yellow-400"></i>
              <i className="fa fa-star text-yellow-400"></i>
              <i className="fa fa-star text-yellow-400"></i>
              <i className="fa fa-star text-gray-300"></i>
              <i className="fa fa-star text-gray-300"></i>
            </>
          ),
          label: "Investor Satisfied"
        },        
        { count: "200+", label: "Projects Completed" },
      ].map((item, index) => (
        <div
          key={index}
          className="text-center stats-item"
          style={{
            flex: isSmallScreen ? "1 1 50%" : "1 1 25%",
            padding: "0 15px",
            cursor: "pointer",
            borderLeft: index !== 0 ? "1px solid rgba(255, 255, 255, 0.3)" : "none",
            transition: "transform 0.3s ease",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "scale(1.1)";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "scale(1)";
          }}
        >
          <h2
            style={{
              color: "#3a9d6a",
              fontSize: "2rem",
              transition: "color 0.3s ease",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = "#ffffff";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = "#3a9d6a";
            }}
          >
            {item.count}
          </h2>
          <p>{item.label}</p>
        </div>
      ))}
    </div>
    </div>
  </div>


    </>
  );
}

export default WorldMap;
