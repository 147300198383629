import React, { useState, useEffect } from 'react';
import Footer from '../../src/components/footer';
import ContactForm from '../../src/assets/contactform.png';
import { useGetFAQCategoriesQuery } from '../redux/api/FAQ';
import { useGetSubCategoryFAQsQuery } from '../redux/api/FAQ';
import Header from '../components/header';
import { Link } from 'react-router-dom';
import ContactImage from '../assets/contact.png';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useAddContactFormDataMutation} from '../redux/api/contact_form';
import axios from "axios";

function Contact() {
  const [formData, setFormData] = useState({
    name:'',
    telephone:'',
    email: '',
    topic: '',
    help:'',
    help_details: '',
  });

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [openIndex, setOpenIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [fontSize, setFontSize] = useState('16px');
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [showAllCategories, setShowAllCategories] = useState(true); 

  const { data: AllCategoriesResponse, isLoading: categoriesLoading } = useGetFAQCategoriesQuery();
  const allcategories = Array.isArray(AllCategoriesResponse?.data) ? AllCategoriesResponse.data : [];

  const { data: SubCategoriesResponse, isLoading: subCategoriesLoading } = useGetSubCategoryFAQsQuery(selectedCategoryId || 1);
  const subcategories = Array.isArray(SubCategoriesResponse?.data) ? SubCategoriesResponse.data : [];
  const [errors, setErrors] = useState({});
  const [addContactFormData] = useAddContactFormDataMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  
    if (name === 'help_details' && (value.length < 10 || value.length > 3000)) {
      setErrorMessage("Enquiry must be between 10 and 3000 characters!");
    } else {
      setErrorMessage('');
    }
  };
  
  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const handleCategorySelection = (category) => {
    setFormData((prevData) => ({
      ...prevData,
      topic: category.title,
    }));
  
    const topicError = validateTopic(category.title);
    setErrors((prevErrors) => ({
      ...prevErrors,
      topic: topicError,
    }));
  
    handleCategoryClick(category.faq_category_id); 
  };
  

  const handleCategoryClick = (categoryId) => {
    if (selectedCategoryId === categoryId) {
      setShowAllCategories(!showAllCategories); 
    } else {
      setSelectedCategoryId(categoryId);
      setShowAllCategories(false);
    }
  };
  const resetForm = () => {
    setFormData({
      name: '',
      telephone: '',
      email: '',
      topic: '',
      help: '',
      help_details: '',
    });
    setSelectedCategoryId(null);
  };
  const validateName = (name) => {
    if (!name) return 'Name is required';
    if (!/^[a-zA-Z\s]+$/.test(name)) return 'Name can only contain letters and spaces';
    return '';
  };
  const validateTopic = (topic) => {
    if (!topic) {
      return 'Topic is required.';
    }
    return '';
  };
  
  const validatePhone = (telephone) => {
    if (!telephone) {
      return 'telephone number cannot be empty';
    }
    const telephoneRegex = /^(?:\+?\d{1,4})?[\s-]?(\(?\d{1,4}\)?[\s-]?)?\d{1,4}[\s-]?\d{1,4}[\s-]?\d{1,4}$/;    // Example regex for US telephone numbers (10 digits, starting with 2-9)
    if (!telephoneRegex.test(telephone)) {
      return 'Invalid phone number format';
    }
    return ''; // No error
  };
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email) return 'Email is required';
    if (!emailRegex.test(email)) return 'Please enter a valid email address';
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if topic is empty
    if (!formData.topic) {
        alert('Please select a topic.');
        return;
    }

    const hasErrors = Object.values(errors).some((error) => error !== '');

    if (hasErrors) {
        alert('Please fill in all required fields before submitting.');
        return;
    }

    try {
        await addContactFormData(formData).unwrap();
        resetForm(); 
    } catch (error) {
        console.error('Failed to submit form:', error);
    }
    handleSubmitContactData();
};

const handleSubmitContactData = async (e) => {

  if (!formData.topic) {
    alert('Please select a topic.');
    return;
}

const hasErrors = Object.values(errors).some((error) => error !== '');

if (hasErrors) {
    alert('Please fill in all required fields before submitting.');
    return;
}

  const dataToSend = {
    fullname: formData.name,
    telephone: formData.telephone,
    email: formData.email,
    topic: formData.topic,
    help: formData.help,
    help_details:formData.help_details
  };

  try {
    await axios.post("http://localhost:5001/send-email", dataToSend);
    alert( "Email sent successfully!");
    resetForm();
    setErrors({});
  } catch (error) {
    console.error("Error sending email:", error);
    alert("An error occurred while sending the email.");
  }
};

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };
  const handleKeyUp = (e) => {
    handleChange(e);
    validateField(e);
  };

  const validateField = (e) => {
    const { name, value } = e.target;
    let error = '';
    
    if (name === 'name') {
      error = validateName(value);
    }
    else if (name === 'topic') {
      error = validateTopic(value);
    }
    else if (name === 'email') {
      error = validateEmail(value);
    }
 
    else if (name === 'telephone') {
      error = validatePhone(value);
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  return (
    <div className="page-wrapper" style={{overflow:"hidden"}}>
      <Header />

      {/* Hero Section */}
      <div
        className="image-container"
        style={{
          position: 'relative',
          width: '100%',
          maxHeight: '400px',
          backgroundColor: '#f4f4df',
        }}
      >
        <img
          src={ContactImage}
          alt="Background"
          style={{
            width: '100%',
            maxHeight: '400px',
            height: 'auto',
            objectFit: 'cover',
          }}
        />
        <div
          className="overlay"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            zIndex: 1,
          }}
        />
        <div
          className="text text-black"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: isLargeScreen?'translate(-220%, -50%)':'translate(-50%, -150%)',
            color: 'white',
            fontSize: isLargeScreen?'50px':'30px',
            fontWeight: 'bold',
            zIndex: 2,
          }}
        >
          Contact Us
        </div>
      </div>

      {/* Form Section */}
      <div className="container-fluid" style={{
            position: 'relative',
            zIndex: 10,
            transform: isLargeScreen?'translate(100px,-100px)':'', 
          }}>
    <div className="row">
    {/* Form Section */}
    <div className="col-md-6 col-sm-12" style={{
            position: 'relative',
            zIndex: 10,
            transform: isLargeScreen?'':'translateY(-100px)', 
          }}>
      <form 
        onSubmit={handleSubmit} 
        style={{ 
          padding: '20px', 
          backgroundColor: 'white', 
          borderRadius: '8px', 
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' 
        }}
      >
        {/* Name Field */}
        <div className="form-group">
          <label htmlFor="name" style={{ fontWeight: 'bold',fontSize:'18px',color:'black' }}>
             Full Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="form-control"
            placeholder="Enter your Full Name"
            required
            value={formData.name}
            onKeyUp={handleKeyUp}
            onChange={handleChange}
            style={{ padding: '10px', borderRadius: '5px', marginTop:"10px",}}
          />
           {errors.name && <span className="error" style={{color:"red"}}>{errors.name}</span>}
        </div>
        <div className="form-group mt-3">
  <label htmlFor="telephone" style={{ fontWeight: 'bold', fontSize: '18px', color: 'black' }}>
    Telephone Number
  </label>
  <PhoneInput
    international
    name="telephone"
    id="telephone"
    className='form-control'
    defaultCountry="LB"
    value={formData.telephone}
    onChange={handlePhoneChange}
    onKeyUp={handleKeyUp}
    onFocus={(e) => e.target.style.border = '2px solid #13264C'}
    style={{
      marginTop:"10px",
      fontSize: '18px',
    }}
  />
  {errors.telephone && <span className="error" style={{ color: 'red' }}>{errors.telephone}</span>}
</div>
        <div className="form-group mt-3">
          <label htmlFor="email" style={{ fontWeight: 'bold',fontSize:'18px',color:'black' }}>
             Email Address
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="form-control"
            placeholder="you@example.com"
            required
            value={formData.email}
            onKeyUp={handleKeyUp}
            onChange={handleChange}
            style={{ padding: '10px', borderRadius: '5px' ,  marginTop:"5px",}}
          />
          {errors.email && <span className="error" style={{color:"red"}}>{errors.email}</span>}
        </div>
         
        {/* Topic Selection */}
        <div className="form-group mt-3">
  <label style={{ fontWeight: 'bold', fontSize: '18px', color: 'black' }}>
    Select a topic:
  </label>
  <div>
    {allcategories
      .filter((category) => showAllCategories || category.faq_category_id === selectedCategoryId)
      .map((category) => (
        <button
          type="button"
          key={category.faq_category_id}
          className={`btn btn-default ${
            selectedCategoryId === category.faq_category_id ? 'btn-active' : ''
          }`}
          onClick={() => handleCategorySelection(category)}
          style={{
            margin: '5px',
            padding: '10px 20px',
            borderRadius: '30px',
            fontWeight: 'bold',
            border: '1px solid #ddd',
            backgroundColor:
              selectedCategoryId === category.faq_category_id ? '#3a9d6a' : '#fff',
            color: selectedCategoryId === category.faq_category_id ? '#fff' : '#000',
          }}
        >
          {category.title}
        </button>
      ))}
  </div>
  {errors.topic && <span className="error" style={{ color: 'red' }}>{errors.topic}</span>}
</div>

        <div className="form-group mb-3">
                <button
                  type="button"
                  onClick={() => setShowAllCategories(!showAllCategories)}
                  className="btn btn-default"
                  style={{
                    backgroundColor: '#3a9d6a',
                    color: '#fff',
                    fontWeight: 'bold',
                    borderRadius: '30px',
                    marginTop: '10px',
                  }}
                >
                  {showAllCategories ? 'Hide Not Selected' : 'See All'}
                </button>
              </div>

              {/* Subcategory Display */}
              {selectedCategoryId && (
                <div className="form-group mt-2">
                  <label style={{ fontWeight: 'bold' ,fontSize:'18px',color:'black'}}>Related Questions</label>
                  {subCategoriesLoading ? (
                    <p>Loading subcategories...</p>
                  ) : subcategories.length > 0 ? (
                    <ul>
                      {subcategories?.map((subcategory, index) => (
  <div key={index} className="mb-3">
    <div
  style={{
    border: '1px solid #e1e1e1',
    borderRadius: '10px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    transition: 'all 0.5s ease-in-out'
  }}
>
      <h2 style={{ marginBottom: '0' }}>
      <button
      className="accordion-button d-flex justify-content-between align-items-center"
      type="button"
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        fontWeight: 'bold',
        color: 'black',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 15px',
        fontSize: fontSize,
      }}
      onClick={() => toggleFaq(index)}
      aria-expanded={openIndex === index ? 'true' : 'false'}
      aria-controls={`collapse-${index}`}
    >
          <span style={{ flexGrow: 1, textAlign: 'left' }}>{subcategory.title}</span>
          <span
            style={{
              transition: 'transform 0.5s ease',
              transform: openIndex === index ? 'rotate(0deg)' : 'rotate(180deg)',
              marginLeft: 'auto',
              fontSize: '1.2rem',
            }}
          >
            ⌃
          </span>
        </button>
      </h2>
      <div
  id={`collapse-${index}`}
  className={`accordion-collapse collapse ${openIndex === index ? 'show' : ''}`}
  style={{
    marginTop: '3px',
    padding: openIndex === index ? '3px 5px' : '0',
    borderTop: openIndex === index ? '1px solid #e1e1e1' : 'none',
    overflow: 'hidden',
    maxHeight: openIndex === index ? '400px' : '0', // Adjust this as needed
    transition: 'max-height 0.3s ease, padding 0.3s ease', // Transition for maxHeight and padding
  }}
>
  <div
    className="accordion-body"
    style={{
      fontSize: '17px',
      fontWeight: '600',
      color: '#003d24',
      marginLeft: '8px',
      marginTop: '7px',
      opacity: openIndex === index ? 1 : 0,
      height: openIndex === index ? 'auto' : '0', // This is optional, but can help with layout
      transition: 'opacity 0.3s ease', // Transition for opacity only
      overflow: openIndex === index ? 'visible' : 'hidden',
      padding: openIndex === index ? '10px 0' : '0', // Adjust padding for body
    }}
  >
          {subcategory.faqs.map((faq) => (
            <h5
              key={faq.faq_id}
              style={{
                fontSize: '17px',
                marginBottom: '3px',
                color: '#333333',
                fontWeight: '400',
              }}
            >
              <Link
                to={`/faq/${faq.faq_id}`}
                style={{
                  color: isHovered === faq.faq_id ? '#3da26f' : 'black',
                  textDecoration: 'none',
                }}
                onMouseEnter={() => setIsHovered(faq.faq_id)}
                onMouseLeave={() => setIsHovered(null)}
              >
                {faq.title}
              </Link>
            </h5>
          ))}
        </div>
      </div>
    </div>
  </div>
))}
                    </ul>
                  ) : (
                    <p>No subcategories available.</p>
                  )}
                </div>
              )}

        {/* Custom Topic Input */}
        <div className="form-group mt-3 mb-2">
          <label htmlFor="help" style={{ fontWeight: 'bold',fontSize:'18px' ,color:'black'}}>
            Or Tell Us What You Need Help With:
          </label>
          <input
            type="text"
            name="help"
            id="help"
            className="form-control"
            placeholder="Enter a help, such as 'notifications'"
            value={formData.help}
            onChange={handleChange}
            style={{ padding: '10px', borderRadius: '5px'  ,marginTop:"5px",}}
          />
        </div>

        {/* Additional Details Field */}
        {formData.help && (
          <div className="form-group mt-2 mb-2">
            <label htmlFor="details" style={{ fontWeight: 'bold' ,fontSize:'18px' ,color:'black'}}>
              Can you give us more details?
            </label>
            <textarea
              name="help_details"
              id="help_details"
              className="form-control"
              placeholder="Add any additional information that we can use to help you."
              rows="4"
              value={formData.help_details}
              onChange={handleChange}
              style={{
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ddd',
                marginTop:"5px",
              }}
            />
          </div>
        )}

        {/* Submit Button */}
        <div className="d-flex justify-content-end">
  <button
    type="submit"
    className="btn btn-success"
    style={{ padding: '10px 20px', borderRadius: '5px', marginTop: '10px' }}
  >
    Submit
  </button>
</div>

      </form>
    </div>

    {/* Image Section */}
    <div className="col-md-5 col-sm-12 text-center"  style={{
            position: 'relative',
            zIndex: 10,
            transform:'translateY(-100px)', 
          }}>
      <img 
        src={ContactForm} 
        alt="Contact Form Illustration" 
        style={{ maxWidth: '60%', height: 'auto' }} 
      />
    </div>
  </div>
     </div>


      <Footer />
    </div>
  );
}

export default Contact;
