import React,{useState,useRef,useEffect} from 'react';
import Footer from '../components/footer'
import Service from '../../src/assets/service4.png';
import Service1 from '../../src/assets/service1.png';
import Service3 from '../../src/assets/service3.png';
import Service2 from '../../src/assets/service2.png';
import S1 from '../assets/portfolio.png';
import Header from '../components/header'
import { Link } from 'react-router-dom';
import S1Img from '../../src/assets/s1img.png';
import S1Img1 from '../../src/assets/s1img1.png';
import { useLocation } from 'react-router-dom';


function Corporate (){
	const [showScroll, setShowScroll] = useState(false);
    const scrollLinkRef = useRef(null);
	const location = useLocation();
	const { name, type } = location.state || { name: '', type: '' }; 
	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

	useEffect(() => {
	  const handleResize = () => {
		setIsLargeScreen(window.innerWidth >= 1024);
	  };
	  window.addEventListener('resize', handleResize);
	  return () => {
		window.removeEventListener('resize', handleResize);
	  };
	}, []);

  return (
    <div className="page-wrapper">
     <Header/>

	
    <div className="sidebar-page-container">
    	<div className="auto-container">
			<br/>
			{name && type ? (
      <>
        <h1 style={{ fontWeight: 'normal', color: 'black' }}>Welcome, {name}!</h1>
        <p style={{ fontSize: '30px', color: 'black' }}>As a {type} person, here are the services we recommend for you:</p>
      </>
    ) :  <h1 style={{ color: 'black',fontWeight:"inherit" }}></h1>}
			<div className="service-title-box">
			<br/>
				<h2 style={{ color: 'black',fontWeight:"normal",fontWeight:"bold" }}>Corporate Financial Managment</h2>
			</div>
        	<div className="row clearfix">
            	
           
                <div className="content-side col-lg-9 col-md-12 col-sm-12 animate__animated animate__fadeInUp">
					<div className="service-detail">
						<div className="inner-box">
							<div className="image">
								<img src={Service} alt="" />
							</div>
							<div className="lower-content">
								<h4 className='text-black'>Custom Solutions Tailored to Target Markets and Sectors
                                </h4>
								<p style={{fontSize:"18px"}}>Our Market-Specific Solutions are built to address the unique challenges and opportunities in various sectors and regions. From industry-specific strategies to regional market insights, we design solutions that maximize performance for clients in specific fields or locations.</p>
							
								
			
								
							</div>
						</div>
					</div>
					<div style={{ maxWidth: "1200px", marginTop: "40px", marginBottom: "30px" }} className="container">
      <div className="row">
      <div className="col-sm-6 animate__animated animate__fadeInLeft animate__delay-1s" style={{ height: "100%" }}>
          <h4
            style={{
              color: "#3a9d6a",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
           Our Corporate Solutions
          </h4>
          <div className="row" style={{ height: "100%" }}>
            <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <h4 className='text-black'>
                <span
                  style={{
                    color: "red",
                    marginRight: "5px",
                     color:"black",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-industry text-success"></i>
                </span>
                Industry-Focused Strategies
              </h4>
              <p style={{ fontSize: "17px" }}>
              We have the expertise to develop investment strategies tailored to high-potential industries, such as technology, energy, real estate, and healthcare.
              </p>
            </div>
            <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <h4 className='text-black'>
                <span
                  style={{
                    marginRight: "5px",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-globe text-success"></i>
                </span>
                Regional Market Insights
              </h4>
              <p style={{ fontSize: "17px" }}>
              We provide actionable insights on emerging and established markets, helping clients make informed choices within specific regions.
              </p>
            </div>
            <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <h4 className='text-black'>
                <span
                  style={{
                    color: "red",
                    marginRight: "5px",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-shield-alt  text-success"></i>
                </span>
                Targeted Risk Management 
              </h4>
              <p style={{ fontSize: "17px" }}>
              Our solutions include risk assessment tools designed for particular markets, helping you mitigate risks unique to each sector or region.
              </p>
            </div>
            <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <h4 className='text-black'>
                <span
                  style={{
                    color: "red",
                    marginRight: "5px",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-sync-alt text-success"></i>
                </span>
                Dynamic Adjustments
              </h4>
              <p style={{ fontSize: "17px" }}>
              As market dynamics shift, we adjust strategies to stay aligned with new economic trends, industry shifts, and regulatory changes.
              </p>
            </div>
          </div>
        </div>
        {/* Left Image Section */}
        <div className="col-sm-6 animate__animated animate__fadeInUp animate__delay-1s" style={{ position: "relative", height: "100%" }}>
          <img
            src={S1Img1}
            alt="Team"
            style={{
              width: "100%",
              height: "65vh", // Ensure the image takes the full height of the container
              borderRadius: "5px",
              objectFit: "cover", // Ensures the image covers the area without distorting
            }}
          />
          {/* Overlay Testimonial (if needed) */}
        </div>

    
     				</div>
				</div>
        <div
        className="image-container animate__animated animate__fadeInUp animate__delay-2s"
        style={{
          position: 'relative',
          width: '100%',
          maxHeight: '400px'
        }}
      >
        <img
          src={S1}
          alt="Background"
          style={{
            width: '100%',
            maxHeight: '400px',
            height: 'auto',
            objectFit: 'cover',
          }}
        />
        <div
          className="overlay"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            zIndex: 1,
          }}
        />
        <div
          className="text animate__animated animate__fadeInRight animate__delay-3s"
          style={{
            position: 'absolute',
			      top: isLargeScreen?'40%':'10%',
            left: isLargeScreen?'40%':'5%',
            transform: isLargeScreen?'translate(-10%, -380%)':'translate(-50%, -200%)',
            color: 'white',
            fontSize: '30px',
            fontWeight: 'bold',
            zIndex: 2,
          }}
        >
         Corporate Financial Managment
        </div>
		<div
          className="text animate__animated animate__fadeInRight animate__delay-3s"
          style={{
            position: 'absolute',
            top: isLargeScreen?'50%':'25%',
            left: isLargeScreen?'40%':'5%',
            transform: isLargeScreen?'translate(-5%, -30%)':'translate(-50%, -15%)',
            color: 'white',
            fontSize: '14px',
            fontWeight: 'bold',
            zIndex: 2,
          }}
        >
         Our Market-Specific Solutions ensure that your investments are guided by a deep understanding of the unique demands of the sectors or regions in which you invest, helping you capture value from targeted opportunities.
        
		</div>
		<div className="col-md-5 col-sm-12 text-center "  style={{
            position: 'relative',
            zIndex: 10,
            transform:isLargeScreen?'translateY(-300px)':'translateY(10px)', 
          }}>
      <img 
        src={S1Img} 
        alt="Contact Form Illustration" 
        style={{ maxWidth: isLargeScreen?'100%':'70%', height: 'auto' }} 
      />
    </div>
      </div>
	

				</div>
             
					
				
				
                <div className="sidebar-side col-lg-3 animate__animated animate__fadeInUp animate__delay-1s" style={{marginTop:isLargeScreen?"":"180px"}}>
                	<aside className="sidebar sticky-top">
						
                    
                        <div className="sidebar-widget categories-widget">
                        <h4 className='text-black'> View Other Services</h4>
						<div className="category-boxed mb-4">
                            <Link to="/service/wealth" className="relative block rounded-lg overflow-hidden">
                                <div className="boxed-inner flex flex-col justify-center items-center p-1 bg-gray-100 rounded-lg transition-shadow duration-300 hover:shadow-lg">
                                <img src={Service1} alt="Portfolio management" className="w-24 h-24 object-cover rounded-lg mb-4" />
                                <h6 className="text-lg font-semibold text-gray-800 text-center">Wealth management </h6>
                                </div>
                            </Link>
                            </div>
                    
							<div className="category-boxed mb-4">
                            <Link to="/service/investment" className="relative block rounded-lg overflow-hidden">
                                <div className="boxed-inner flex flex-col justify-center items-center p-1 bg-gray-100 rounded-lg transition-shadow duration-300 hover:shadow-lg">
                                <img src={Service2} alt="Investment Advisory" className="w-24 h-24 object-cover rounded-lg mb-4" />
                                <h6 className="text-lg font-semibold text-gray-800 text-center">Investment Advisory</h6>
                                </div>
                            </Link>
                            </div>

                            <div className="category-boxed mb-4">
                            <Link to="/service/financial" className="relative block rounded-lg overflow-hidden">
                                <div className="boxed-inner flex flex-col justify-center items-center p-1 bg-gray-100 rounded-lg transition-shadow duration-300 hover:shadow-lg">
                                <img src={Service3} alt="Wealth Planning" className="w-24 h-24 object-cover rounded-lg mb-4" />
                                <h6 className="text-lg font-semibold text-gray-800 text-center">Financial Planning</h6>
                                </div>
                            </Link>
                            </div>

							
	
							
							
						</div>
						
						
					</aside>
				</div>
				
			</div>
		</div>
	</div>
      <Footer />
	  <div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>
    </div>
  );
};

export default Corporate;
