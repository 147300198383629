import React,{useState,useRef,useEffect} from 'react';
import Footer from '../components/footer'
import Service from '../../src/assets/service3.png';
import Service1 from '../../src/assets/service1.png';
import Service2 from '../../src/assets/service2.png';
import Service4 from '../../src/assets/service4.png';
import S3 from '../assets/S3img.png';
import Header from '../components/header'
import { Link } from 'react-router-dom';
import S1Img from '../../src/assets/s1img.png';
import S1Img1 from '../../src/assets/financial.webp';
import S1Img2 from '../../src/assets/financial1.webp';
import { useLocation } from 'react-router-dom';


function Financial (){
	const [showScroll, setShowScroll] = useState(false);
    const scrollLinkRef = useRef(null);
	const location = useLocation();
	const { name, type } = location.state || { name: '', type: '' }; 
	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

	useEffect(() => {
	  const handleResize = () => {
		setIsLargeScreen(window.innerWidth >= 1024);
	  };
	  window.addEventListener('resize', handleResize);
	  return () => {
		window.removeEventListener('resize', handleResize);
	  };
	}, []);

  return (
    <div className="page-wrapper">
     <Header/>

	
    <div className="sidebar-page-container">
    	<div className="auto-container">
			<br/>
			<div className="service-title-box">
			<br/>
				<h2 style={{ color: 'black',fontWeight:"bold"}}>Financial Planning</h2>
			</div>
        	<div className="row clearfix">
            	
           
                <div className="content-side col-lg-9 col-md-12 col-sm-12 animate__animated animate__fadeInUp">
					<div className="service-detail">
						<div className="inner-box">
							<div className="image">
								<img src={Service} alt="" />
							</div>
							<div className="lower-content">
								<p className='text-black' style={{fontSize:"25px"}}>Tailored Financial Strategies for a Secure and Prosperous Future
                                </p>
								<p style={{fontSize:"20px"}}>At Beta, we believe that a strong financial plan is the foundation of lasting wealth. Our comprehensive financial planning service is designed to align your financial goals with actionable strategies, ensuring a secure and prosperous future. Here’s a detailed breakdown of what we offer</p>
								<blockquote>
								<span className='text-success fw-bold'  style={{fontSize:"18px"}}>Why Choose Beta</span>
                <br/>
                                 <ol>
									<li>1- Expert advisors with deep industry knowledge.</li>
									<li> 2- Tailored solutions that prioritize your unique needs.
									</li>
									<li>3- A holistic approach to wealth management, covering every aspect of your financial journey.</li>
								 </ol>
								</blockquote>
							</div>
						</div>
					</div>
					<h4
            style={{
              color: "#3a9d6a",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          className='animate__animated animate__fadeInUp animate__delay-2s'>
            What We Offer
          </h4>
					<div style={{ maxWidth: "1200px",  marginBottom: "30px" }} className="container">
      <div className="row">
      <div className="col-sm-6 animate__animated animate__fadeInLeft animate__delay-2s" style={{ height: "100%" }}>
          
          <div className="row" style={{ height: "100%" }}>
            <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <h5 className='text-black mt-3'>
                <span
                  style={{
                    color: "red",
                    marginRight: "5px",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-bullseye text-success"></i>
                </span>
                Personalized Goal Setting
              </h5>
              <p style={{ fontSize: "13px" }}>
			  We start by understanding your unique financial aspirations—whether it’s saving for retirement, funding your child’s education, purchasing a home, or growing your investment portfolio. Together, we create clear, measurable goals tailored to your needs.
              </p>
            </div>
            <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <h5 className='text-black'>
                <span
                  style={{
                    color: "red",
                    marginRight: "5px",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-dollar-sign text-success"></i>
                </span>
				Income & Expense Analysis
              </h5>
              <p style={{ fontSize: "13px" }}>
			  Our team conducts a thorough analysis of your income and expenses to help identify opportunities for optimizing savings. By understanding your cash flow, we create a plan that ensures your wealth grows without compromising your lifestyle.
              </p>
            </div>
            <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <h5 className='text-black'>
                <span
                  style={{
                    color: "red",
                    marginRight: "5px",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-chart-pie text-success"></i>
                </span>
                Investment Strategy Development
              </h5>
              <p style={{ fontSize: "13px" }}>
			  Beta’s financial planning includes crafting a personalized investment strategy aligned with your risk tolerance, time horizon, and goals. Whether you prefer conservative growth or aggressive returns, we ensure your portfolio is structured for maximum impact.
              </p>
            </div>
			<div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <h5 className='text-black'>
                <span
                  style={{
                    color: "red",
                    marginRight: "5px",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-shield-alt text-success"></i>
                </span>
				Risk Management & Insurance Planning
              </h5>
              <p style={{ fontSize: "13px" }}>
			  Safeguard your wealth with a robust risk management plan. We assess potential risks to your financial stability and recommend insurance solutions, such as life, health, and asset protection, to provide peace of mind for you and your family.
              </p>
            </div>
          </div>
        </div>
        {/* Left Image Section */}
        <div className="col-sm-6 animate__animated animate__fadeInUp animate__delay-2s" style={{ position: "relative", height: "100%" }}>
          <img
            src={S1Img1}
            alt="Team"
            style={{
              width: "100%",
              height: "75vh", 
              borderRadius: "5px",
              objectFit: "cover", // Ensures the image covers the area without distorting
            }}
          />
          {/* Overlay Testimonial (if needed) */}
        </div>



    
     				</div>
					 <div className="row" style={{marginTop:isLargeScreen?"20px":"30px"}}>
  {/* Left Image Section */}
  <div className="col-sm-6 animate__animated animate__fadeInUp animate__delay-2s" style={{ position: "relative", height: "100%" }}>
    <img
      src={S1Img2} 
      alt="Team"
      style={{
        width: "100%",
        height: "85vh", // Ensure the image takes the full height of the container
        borderRadius: "5px",
        objectFit: "cover", // Ensures the image covers the area without distorting
      }}
    />
  </div>

  <div className="col-sm-6 animate__animated animate__fadeInLeft animate__delay-2s" style={{ height: "100%" }}>
   
    <div className="row" style={{ height: "100%" }}>
      {/* Personalized Tax Optimization */}
      <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <h4 className='text-black mt-3'>
          <span
            style={{
              color: "red",
              marginRight: "5px",
              display: "inline-block",
            }}
          >
            <i className="fa fa-calculator text-success"></i>
          </span>
         Tax Optimization
        </h4>
        <p style={{ fontSize: "13px" }}>
          Efficient tax planning is essential to maximizing your wealth. Our experts analyze your current tax situation and recommend strategies to minimize liabilities while adhering to legal guidelines, helping you keep more of what you earn.
        </p>
      </div>

      {/* Retirement Planning */}
      <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <h4 className='text-black mt-3'>
          <span
            style={{
              color: "red",
              marginRight: "5px",
              display: "inline-block",
            }}
          >
            <i className="fa fa-calendar-check text-success"></i>
          </span>
          Retirement Planning
        </h4>
        <p style={{ fontSize: "13px" }}>
          Whether retirement is years away or just around the corner, we help you plan for a financially secure future. From calculating your retirement needs to designing income streams, we ensure you can enjoy the lifestyle you’ve envisioned.
        </p>
      </div>

      {/* Estate & Legacy Planning */}
      <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <h4 className='text-black mt-3'>
          <span
            style={{
              color: "red",
              marginRight: "5px",
              display: "inline-block",
            }}
          >
            <i className="fa fa-gavel text-success"></i>
          </span>
          Estate & Legacy Planning
        </h4>
        <p style={{ fontSize: "13px" }}>
          Plan for the future with confidence. Our estate planning services help you preserve your wealth and ensure it is distributed according to your wishes. We work with legal professionals to design strategies that reduce estate taxes and protect your legacy.
        </p>
      </div>

      {/* Ongoing Monitoring & Adjustments */}
      <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <h4 className='text-black mt-3'>
          <span
            style={{
              color: "red",
              marginRight: "5px",
              display: "inline-block",
            }}
          >
            <i className="fa fa-sync-alt text-success"></i>
          </span>
          Ongoing Monitoring & Adjustments
        </h4>
        <p style={{ fontSize: "13px" }}>
          Financial planning isn’t a one-time activity—it evolves as your life and circumstances change. At Beta, we provide regular reviews and updates to your financial plan, ensuring it stays relevant and effective in meeting your goals.
        </p>
      </div>
    </div>
  </div>
</div>

									</div>
									<div
							className="image-container animate__animated animate__fadeInUp animate__delay-2s"
							style={{
							position: 'relative',
							width: '100%',
							maxHeight: '400px'
							}}
						>
							<img
							src={S3}
							alt="Background"
							style={{
								width: '100%',
								maxHeight: '400px',
								height: 'auto',
								objectFit: 'cover',
							}}
							/>
							<div
							className="overlay"
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								backgroundColor: 'rgba(0, 0, 0, 0.85)',
								zIndex: 1,
							}}
							/>
							<div
							className="text animate__animated animate__fadeInRight animate__delay-3s"
							style={{
								position: 'absolute',
								top: isLargeScreen?'40%':'30%',
								left: isLargeScreen?'40%':'5%',
								transform: isLargeScreen?'translate(-10%, -380%)':'translate(-50%, -200%)',
								color: 'white',
								fontSize: '30px',
								fontWeight: 'bold',
								zIndex: 2,
							}}
							>
							Financial Planning
							</div>
							<div
							className="text animate__animated animate__fadeInRight animate__delay-3s"
							style={{
								position: 'absolute',
								top: isLargeScreen?'50%':'40%',
								left: isLargeScreen?'40%':'5%',
								transform: isLargeScreen?'translate(-5%, -30%)':'translate(-50%, -15%)',
								color: 'white',
								fontSize: '12px',
								fontWeight: 'bold',
								zIndex: 2,
							}}
							>
							Start building your financial future today with Beta’s expert financial planning services. Contact us to schedule a consultation and take the first step toward achieving your goals.
							</div>
							<div className="col-md-5 col-sm-12 text-center "  style={{
								position: 'relative',
								zIndex: 10,
								transform:isLargeScreen?'translateY(-300px)':'translateY(200px)', 
							}}>
						<img 
							src={S1Img} 
							alt="Contact Form Illustration" 
							style={{ maxWidth: isLargeScreen?'100%':'80%', height: 'auto' }} 
						/>
						</div>
						</div>
	
          
	

				</div>
             
					
				
				
                <div className="sidebar-side col-lg-3 animate__animated animate__fadeInUp animate__delay-1s">
                	<aside className="sidebar sticky-top">
						
                    
                        <div className="sidebar-widget categories-widget">
                        <h4 className='text-black'> View Other Services</h4>
						<div className="category-boxed mb-4">
                            <Link to="/service/wealth" className="relative block rounded-lg overflow-hidden">
                                <div className="boxed-inner flex flex-col justify-center items-center p-1 bg-gray-100 rounded-lg transition-shadow duration-300 hover:shadow-lg">
                                <img src={Service1} alt="Portfolio management" className="w-24 h-24 object-cover rounded-lg mb-4" />
                                <h6 className="text-lg font-semibold text-gray-800 text-center">Wealth management </h6>
                                </div>
                            </Link>
                            </div>
                    
							<div className="category-boxed mb-4">
                            <Link to="/service/investment" className="relative block rounded-lg overflow-hidden">
                                <div className="boxed-inner flex flex-col justify-center items-center p-1 bg-gray-100 rounded-lg transition-shadow duration-300 hover:shadow-lg">
                                <img src={Service2} alt="Investment Advisory" className="w-24 h-24 object-cover rounded-lg mb-4" />
                                <h6 className="text-lg font-semibold text-gray-800 text-center">Investment Advisory</h6>
                                </div>
                            </Link>
                            </div>

                            
                            <div className="category-boxed mb-4">
                            <Link to="/service/corporate" className="relative block rounded-lg overflow-hidden">
                                <div className="boxed-inner flex flex-col justify-center items-center p-1 bg-gray-100 rounded-lg transition-shadow duration-300 hover:shadow-lg">
                                <img src={Service4} alt="Corporate Financial Management" className="w-24 h-24 object-cover rounded-lg mb-4" />
                                <h6 className="text-lg font-semibold text-gray-800 text-center">Corporate Financial Management</h6>
                                </div>
                            </Link>
                            </div>

							
	
							
							
						</div>
						
						
					</aside>
				</div>
				
			</div>
		</div>
	</div>
      <Footer />
	  <div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>
    </div>
  );
};

export default Financial;
