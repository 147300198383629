import React, { useState, useEffect } from 'react';
import Invest1 from '../../src/assets/invest1.png';
import Invest2 from '../../src/assets/invest2.png';
import Invest3 from '../../src/assets/invest3.png';

const InvestmentProcess = () => {
  const InvestementData = [
    {
      title1: "Investment Consultancy and Assessment",
      text1:
        "Our relationship with clients begins with an in-depth consultancy session where we assess their financial situation, goals, and risk tolerance. This phase is crucial for creating an investment approach aligned with each client’s vision.",
      image1: Invest1,
    },
    {
      title2: "Broker Selection and Fund Deposit",
      text2:
        "We provide clients with a choice of our partnered, reputable brokerages where they can securely deposit their funds. While Beta Investment Group is not a brokerage, our partners help facilitate investments with trusted security measures, ensuring clients’ peace of mind.",
      image2: Invest2,
    },
    {
      title3: "Portfolio Growth and Management",
      text3:
        "Once funds are deposited with the chosen broker, our team initiates the active investment strategy, continuously monitoring and adjusting based on market conditions. We take an agile approach, allowing clients to benefit from proactive management designed to maximize returns and minimize risks.",
      image3: Invest3,
    },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [animateOut1, setAnimateOut1] = useState(false);
  const [animateIn1, setAnimateIn1] = useState(false); // For the entry animation

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimateOut1(true); // Trigger exit animation
      setTimeout(() => {
        setCurrentStep((prevStep) => (prevStep + 1) % InvestementData.length);
        setAnimateOut1(false); // Reset the exit animation state
        setAnimateIn1(true); // Trigger entry animation
      }, 1000); // 1 second for the "animateOut" transition
    }, 4000); // Change step every 4 seconds

    return () => clearInterval(interval);
  }, [InvestementData.length]);

  const handleChangeContent1 = (index1) => {
    setAnimateOut1(true); // Trigger exit animation
    setTimeout(() => {
      setCurrentStep(index1);
      setAnimateOut1(false); // Reset the exit animation state
      setAnimateIn1(true); // Trigger entry animation
    }, 1000); // 1 second for the "animateOut" transition
  };

  return (
    <div className="container" style={{ paddingLeft: "0", paddingRight: "0" }}>
      {/* Why Choose Us Section */}
      <div className="text-left mb-4">
        <h1
          className="text-success text-uppercase"
          style={{ fontWeight: "bold", fontSize: "1.5rem", lineHeight: "1.3",marginLeft:"10px" }}
        >
          Our Proven Investment Process
        </h1>
      </div>

      {/* Content Section */}
      <div className="row" style={{ margin: "0" }}>
        {/* Image Section */}
        <div
          className={`col-lg-6 col-md-12 animate__animated ${
            animateOut1 ? "animate__fadeOut" : animateIn1 ? "animate__fadeInUp" : "animate__fadeInUp"
          }`}
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            animationDuration: "2s", // 2 seconds for animation
          }}
        >
          <img
            src={InvestementData[currentStep][`image${currentStep + 1}`]}
            alt={`Step ${currentStep + 1}`}
            className="img-responsive img-thumbnail"
            style={{
              width: "100%",
              height: "400px",
              objectFit: "cover",
              border: "none",
            }}
          />
        </div>

        {/* Timeline Section */}
        <div
          className="col-lg-6 col-md-12"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <div className="timeline mt-2">
            {InvestementData.map((data, index) => (
              <div
                key={index}
                className={`timeline-item mb-3 ${
                  currentStep === index ? "active" : ""
                }`}
                onClick={() => handleChangeContent1(index)}
                style={{
                  cursor: "pointer",
                  borderLeft:
                    currentStep === index
                      ? "3px solid #3a9d6a"
                      : "3px solid transparent",
                  paddingLeft: "10px",
                  transition: "all 0.3s ease",
                }}
              >
                <span
                  className={`text-success fw-bold ${
                    currentStep === index ? "font-weight-bold" : ""
                  }`}
                  style={{ fontSize: "1.5rem" }}
                >
                  {data[`title${index + 1}`]}
                </span>
                <div className="mt-1">
                  <p className="text-muted" style={{ fontSize: "0.9rem" }}>
                    {data[`text${index + 1}`]}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentProcess;
